export default {
  documents: 'Quản lý tài liệu',
  addDocument: 'Thêm tài liệu',
  updateDocument: 'Cập nhật tài liệu',
  name: 'Tên',
  action: 'Thao tác',
  level: 'Cấp',
  skill: 'Kỹ năng',
  type: 'Loại',
  delete: 'Xóa',
  deleteDocuments: 'Xóa tài liệu',
  confirmDelete: 'Xác nhận xóa',
  update: 'Cập nhật',
  error: 'Lỗi',
  addDocuments: 'Thêm tài liệu',
  cancel: 'Hủy',
  creatAt: 'Ngày tạo',

  create: {
    name: 'Tên',
    enterDescription: 'Nhập mô tả',
    nameOfQuestion: 'Tên câu hỏi',
    level: 'Cấp',
    skill: 'Kỹ năng',
    description: 'Mô tả',
    uploadFile: 'Tải lên tài liệu',
    updateFile: 'Cập nhật tài liệu',
    missingName: 'Vui lòng nhập tên tài liệu',
    missingLength: 'Tên tài liệu không được vượt quá 256 ký tự'
  },

  options: {
    beginner: 'Người mới',
    intermediate: 'Trung cấp',
    advanced: 'Nâng cao',
    listening: 'Nghe',
    reading: 'Đọc',
    writing: 'Viết',
    speaking: 'Nói'
  }
};
