import Radio from 'components/Form/Radio';

import backgroundImg from 'assets/image/OtherPage/backgroundLogin.jpg';

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
`;

export const BackgroundImage = styled.div`
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  opacity: 0.5;
  z-index: 1;
`;

export const Logo = styled.img`
  height: 200px;
  width: auto;
  object-fit: contain;
`;

export const Header = styled.div`
  flex-basis: 20%;
  text-align: center;
  padding-top: 50px;
  z-index: 2;

  border-box: box-sizing;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;

  .loginTitle {
    display: flex;
    align-item: center;
    line-height: normal;
    margin-left: 15px;
  }
`;

export const WrapperForm = styled.div`
  min-width: 32px;
  width: 450px;
  max-width: 90vw;
  max-height: 100%;
  background: #ffffff;
  box-shadow: 0px 41px 56px rgba(0, 18, 101, 0.12);
  border-radius: 16px;
  padding: 25px 28px;
  z-index: 2;

  .ant-tabs-nav-wrap {
    justify-content: left;
  }

  .ant-form-item {
    margin-bottom: 14px;

    label {
      font-weight: ${({ theme }) => theme?.fontWeight?.extraBold};
      font-size: ${({ theme }) => theme?.fontSize?.sm};
      line-height: 20px;

      flex-direction: row-reverse;
      gap: 5px;
    }
  }
`;

export const Title = styled.h6`
  font-style: normal;
  font-weight: ${({ theme }) => theme?.fontWeight?.extraBold};
  font-size: 28px;
  line-height: 28px;
  color: ${({ theme }) => theme?.colors?.blueNew};
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

export const ForgotPass = styled.div`
  margin: 12px 0 28px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;
    background: none;
    cursor: pointer;
  }
`;

export const BoxRadio = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-left: 1px;
    font-size: ${({ theme }) => theme?.fontSize?.base};
    font-style: normal;
    font-weight: ${({ theme }) => theme?.fontWeight?.regular};
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .ant-radio {
    color: ${({ theme }) => theme?.colors?.blueNew};
  }
`;

export const ForgotPassWord = styled.span`
  font-size: ${({ theme }) => theme?.fontSize?.base};
  font-weight: ${({ theme }) => theme?.fontWeight?.regular};
  color: ${({ theme }) => theme?.colors?.blueNew};
  line-height: 24px;
  text-decoration-line: underline;

  cursor: pointer;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #000000;
  margin-bottom: 8px;

  span {
    color: ${({ theme }) => theme?.colors?.red};
  }
`;

export const RadioCustom = styled(Radio)`
  .ant-radio {
    top: 0.13em;
  }
  .ant-radio-inner {
    width: 13px;
    height: 13px;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 8px;
      min-height: 8px;
      margin: 0;
      width: 8px;
      height: 8px;

      background-color: ${({ theme }) => theme?.colors?.yellow2};
    }
  }
`;
export const ErrorMessage = styled.div`
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme?.colors?.yellow2};
  margin-bottom: 34px;
`;

export const LoadingWrapper = styled.div`
  margin-right: 10px;
`;

export const LangWrap = styled.div`
  z-index: 1;
  position: absolute;
  right: 20px;
  top: 20px;

  button {
    border-radius: 24px;
    padding: 10px 14px;
    background: none;
    text-align: left;
  }

  .active {
    background-color: #c9c9c9;
  }
`;
