export default {
  foldersManage: 'Thư mục',
  name: 'Tên',
  folders: 'Thư mục - Tài liệu',
  addFolder: 'Thêm mới',
  addNewFolder: 'Thêm mới thư mục',
  deleteConfirm: 'Xoá thư mục',
  delete: 'Xoá',

  listFile: 'Danh sách tập tin',
  createdAt: 'Ngày tạo',
  nameFile: 'Tên tập tin',
  tags: 'Thẻ',
  actions: 'Hành động',
  clickOrDrag: 'Nhấn chọn hoặc kéo tập tin để tải lên',
  placeholderSearch: 'Tìm kiếm tập tin',
  addFile: 'Thêm tập tin',
  importContent: 'Nhập nội dung',
  add: 'Thêm',

  contentConfirm: 'Bạn có chắc chắn muốn xóa tập tin này không?',
  error: 'Lỗi',
  deleteComplete: 'Xóa thành công',
  thumbnail: 'Hình ảnh',
  typeFile: 'Loại tập tin',
  numericalOrder: 'No',
  titleEditFile: 'Sửa tập tin',
  importNameFile: 'Nhập tên tập tin',
  editComplete: 'Sửa thành công',
  fileFormatError: 'File tải lên không đúng định dạng',
  fileLengthLimitError:
    'File tải lên không được vượt quá {{fileLengthLimit}}MB',
  pleaseImportName: 'Vui lòng nhập tên tập tin',
  pleaseImportTags: 'Vui lòng nhập thẻ',
  pleaseChooseType: 'Vui lòng chọn loại tập tin',
  assetCode: 'Mã tập tin',
  colInvite: 'Mã mời',
  file: 'Tập tin',
  pleaseImportNameFolder: 'Vui lòng nhập tên thư mục',
  nameFolder: 'Tên',
  contentConfirmFolder: 'Bạn có chắc chắn muốn xóa thư mục này không?',
  contentConfirmFolderDesc:
    'Tất cả những thư mục và tập tin bên trong sẽ bị xoá.',
  deleteFile: 'Xoá tập tin',
  deleteFolder: 'Xoá thư mục',
  currentFolder: 'Thư mục hiện tại',
  searchAsset: 'Tìm kiếm tài liệu',
  assetManage: 'Quản lý thư mục',
  btnSearch: 'Tìm kiếm',
  pleaseUploadFile: 'Vui lòng tải lên tập tin',
  parentFolder: 'Thư mục cha',
  edit: 'Chỉnh sửa',
  editFolder: 'Sửa thư mục',
  searchFor: 'Kết quả tìm kiếm',
  fileFomatInvalid: 'Vui lòng chọn ảnh có định dạng PNG hoặc JPG',
  invalidFileName: 'Tên tập tin không hợp lệ',
  invalidFolderName: 'Tên thư mục không hợp lệ',
  shareFile: 'Công khai tập tin',
  upload: 'Tải lên',
  none: 'Thư mục gốc',
  download: 'Tải xuống',
  searchingFor: 'Tìm kiếm với từ khóa',
  showMore: 'Tải thêm',
  addDocument: 'Thêm tài liệu',
  cancel: 'Hủy',
  deleteDocument: 'Xoá tài liệu'
};
