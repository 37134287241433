import React, { useState, useEffect } from 'react';
import { Button, Menu } from 'antd';
import { ReactSVG as SVG } from 'react-svg';
import {
  useHistory,
  Link
  // useLocation,
  // useParams,
  // generatePath
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useTranslation from 'hooks/useTranslation';

import { logOut } from 'store/other/authSlice';
// import { changeLanguageAction } from 'store/other/layoutSlice';
import { getNotifications } from 'store/other/notificationSlice';

import { data } from './data';
import { preAdmin } from 'routes/constants';
import { updateFCMToken } from 'services/apis/admin/notification';

import MenuLabel from './components/MenuLabel';
import Notification from 'components/commons/Notification';

import ModalConfirm from 'components/commons/ModalConfirm';

import logout from 'assets/icons/Union.svg';
import logoTes from 'assets/image/logo-sidebar-te.png';
import back from 'assets/icons/back.svg';
import menuIcon from 'assets/icons/menu.svg';
// import vn from 'assets/icons/vn.png';
// import en from 'assets/icons/en.png';
// import noImage from 'assets/image/noImage.png';

import * as S from './styled';

export default function Layout({ children }) {
  const { t } = useTranslation('layout');

  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();

  const { isAuth, userInfo, myPermission } = useSelector(
    (state) => state?.authReducer
  );
  // const { language } = useSelector(state => state.layoutReducer);

  const [collapsed, setCollapsed] = useState(false);
  const [visibleLogout, setVisibleLogout] = useState(false);

  useEffect(() => {
    if (isAuth) {
      dispatch(getNotifications());
    }

    if (window.screen.width < 769) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isAuth, dispatch]);

  const handleLogOutAPI = async () => {
    updateFCMToken(userInfo?.id, {
      fcm_token: ''
    }).catch(() => {
      return;
    });
    dispatch(logOut());
  };

  return (
    <S.Layout>
      <S.Layout>
        {!collapsed && window.screen.width < 769 && (
          <S.BackgroundOverlay
            onClick={() => setCollapsed(true)}
          ></S.BackgroundOverlay>
        )}
        {!collapsed ? (
          <S.Sd
            width={250}
            className="site-layout-background"
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            trigger={null}
            $showTab={collapsed}
            style={{ background: '#ffffff' }}
          >
            <S.HDSB>
              {!collapsed && (
                <S.DFlex className="workspaceLogo">
                  <Link to="/">
                    <S.Logo>
                      <img src={logoTes} alt="" />
                    </S.Logo>
                  </Link>
                </S.DFlex>
              )}
              <div
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '22px',
                  cursor: 'pointer'
                }}
              >
                <SVG
                  onClick={() => setCollapsed(!collapsed)}
                  className="mainColor"
                  src={menuIcon}
                />
              </div>
            </S.HDSB>
            <S.WrapMenuAndLogout>
              <S.WrapMenu>
                <Menu
                  mode="inline"
                  defaultSelectedKeys="P1"
                  selectedKeys={
                    data?.find(
                      (item) =>
                        item?.keyword ===
                        window.location.pathname
                          ?.replace(`${preAdmin}/`, '')
                          ?.split('/')
                          ?.at(0)
                    )?.id
                  }
                >
                  {data?.map((item) => (
                    <React.Fragment key={item?.id}>
                      {item?.menus?.length > 0 ? (
                        <Menu.SubMenu
                          key={item?.id}
                          icon={<SVG className="iconMenu" src={item?.icon} />}
                          title={t(item?.title)}
                        >
                          {item?.menus?.map((k) => (
                            <Menu.Item
                              icon={<SVG className="iconMenu" src={k?.icon} />}
                              key={k?.id}
                              disabled={
                                item?.permissionSidebarKey !== 'settings' &&
                                !myPermission[k?.permissionSidebarKey]
                              }
                              className={
                                item?.permissionSidebarKey !== 'settings' &&
                                !myPermission[k?.permissionSidebarKey]
                                  ? 'forbiden'
                                  : ''
                              }
                            >
                              <MenuLabel data={k} setCollapsed={setCollapsed}>
                                {t(k?.title)}
                              </MenuLabel>
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      ) : (
                        <Menu.Item
                          key={item?.id}
                          icon={<SVG className="iconMenu" src={item?.icon} />}
                          disabled={
                            item?.permissionSidebarKey !== 'settings' &&
                            !myPermission[item?.permissionSidebarKey]
                          }
                          className={
                            item?.permissionSidebarKey !== 'settings' &&
                            !myPermission[item?.permissionSidebarKey]
                              ? 'forbiden'
                              : ''
                          }
                        >
                          <MenuLabel data={item} setCollapsed={setCollapsed}>
                            {t(item?.title)}
                          </MenuLabel>
                        </Menu.Item>
                      )}
                    </React.Fragment>
                  ))}
                </Menu>
              </S.WrapMenu>

              {/* <S.LangWrap>
                <button
                  type="button"
                  className={language === 'vi' ? 'active' : ''}
                  onClick={() => {
                    i18n.changeLanguage('vi');
                    dispatch(changeLanguageAction('vi'));
                  }}
                >
                  <img src={vn} alt="" />
                </button>
                <button
                  type="button"
                  className={language === 'en' ? 'active' : ''}
                  onClick={() => {
                    i18n.changeLanguage('en');
                    dispatch(changeLanguageAction('en'));
                  }}
                >
                  <img src={en} alt="" />
                </button>
              </S.LangWrap> */}
              <S.WrapLogOut>
                <S.LogOut onClick={() => setVisibleLogout(!visibleLogout)}>
                  <img src={logout} alt="" />
                  {t('logOut')}
                </S.LogOut>
                <ModalConfirm
                  title={t('logOut')}
                  onCancel={() => setVisibleLogout(!visibleLogout)}
                  open={visibleLogout}
                  des={`<b>${t('sureComfirm')}</b>`}
                  onOk={handleLogOutAPI}
                  titleButton={t('logOut')}
                />
              </S.WrapLogOut>
            </S.WrapMenuAndLogout>
          </S.Sd>
        ) : null}
        <S.Layout>
          <S.Hd className={`header ${collapsed && 'menu-collapsed'}`}>
            <S.DFlex className="space-between">
              {collapsed && (
                <div>
                  <SVG
                    onClick={() => setCollapsed(!collapsed)}
                    className="mainColor"
                    src={menuIcon}
                  />
                </div>
              )}
              <Button
                type="text"
                onClick={() => history.goBack()}
                className="button-back"
              >
                <SVG src={back} />
              </Button>
              <S.NotificationWrap>
                <Notification />
              </S.NotificationWrap>
            </S.DFlex>
            {/* <Dropdown menu={{ items }}>
                <Space>
                  <S.DFlex style={{ alignItems: 'center', gap: '24px' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      <Avatar
                        size={40}
                        src={
                          userProfile?.avatar
                            ? `${userProfile?.avatar}?v=${new Date().getTime()}`
                            : avatar
                        }
                      ></Avatar>

                      <Text
                        isBold
                        style={{
                          color: '#303030',
                          textTransform: 'capitalize',
                        }}
                      >
                        {userProfile?.name || ''}
                      </Text>
                    </div>
                  </S.DFlex>
                </Space>
              </Dropdown> */}
          </S.Hd>
          <S.Contents>{children}</S.Contents>
        </S.Layout>
      </S.Layout>
    </S.Layout>
  );
}
