export default {
  documents: 'Documents',
  addDocument: 'Add New',
  createDocument: 'Create Document',
  updateDocument: 'Update Document',
  name: 'Name',
  action: 'Actions',
  level: 'Level',
  skill: 'Skill',
  type: 'Type',
  delete: 'Delete',
  deleteDocuments: 'Delete Documents',
  confirmDelete: 'Do you want delete documents ',
  update: 'Update',
  error: 'Error',
  addDocuments: 'Add Documents',
  cancel: 'Cancel',
  createBy: 'Created By',
  enterDocumentName: 'Enter document name',
  creatAt: 'Created At',
  description: 'Description',

  create: {
    name: 'Name',
    enterDescription: 'Enter Description',
    nameOfQuestion: 'Question Name',
    level: 'Level',
    skill: 'Skill',
    description: 'Description',
    uploadFile: 'Upload File',
    updateFile: 'Update File',
    missingName: 'Please enter a name',
    missingFile: 'Please upload a file',
    missingLevel: 'Please select level',

    missingNameDoc: 'Please enter document name',
    missingLength: 'Document is not exceeded 256 characters'
  },

  options: {
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    advanced: 'Advanced',
    listening: 'Listening',
    reading: 'Reading',
    writing: 'Writing',
    speaking: 'Speaking'
  },

  filter: {
    level: 'Level',
    skill: 'Skill',
    submit: 'Filter',
    reset: 'Reset'
  }
};
