export default {
  foldersManage: 'Folders',
  name: 'Name',
  folders: 'Folders - Documents',
  addFolder: 'Add New',
  addNewFolder: 'Add New Folder',
  deleteConfirm: 'Delete',
  delete: 'Delete',
  enterFolderName: 'Enter folder name',

  listFile: 'Files',
  createdAt: 'Created at',
  nameFile: 'File name',
  tags: 'Tags',
  actions: 'Actions',
  clickOrDrag: 'Drag or drop to upload file',
  placeholderSearch: 'Search file',
  addFile: 'Add file',
  importContent: 'Enter content',
  add: 'Add',
  contentConfirm: 'Are you sure you want to delete this file?',
  error: 'Error',
  deleteComplete: 'Deleted successfully.',
  thumbnail: 'Thumbnail',
  typeFile: 'File type',
  numericalOrder: 'No',
  titleEditFile: 'Edit file',
  importNameFile: 'Enter file name',
  editComplete: 'Updated successfully.',
  fileFormatError: 'Invalid file or improperly formatted!',
  fileLengthLimitError:
    'The total file size does not exceed {{fileLengthLimit}}MB',
  pleaseImportName: 'Please enter file name.',
  pleaseImportTags: 'Please enter tags.',
  pleaseChooseType: 'Please choose file type.',
  assetCode: 'File code',
  colInvite: 'Invitation code',
  file: 'File',
  pleaseImportNameFolder: 'Please enter folder name.',
  nameFolder: 'Name',
  contentConfirmFolder: 'Are you sure you want to delete this folder?',
  contentConfirmFolderDesc: 'All folders and files will be deleted.',
  deleteFile: 'Delete file',
  deleteFolder: 'Delete folder',
  currentFolder: 'Current folder',
  searchAsset: 'Search file',
  assetManage: 'File management',
  btnSearch: 'Search',
  pleaseUploadFile: 'Please upload file.',
  parentFolder: 'Parent folder',
  edit: 'Edit',
  editFolder: 'Edit folder',
  searchFor: 'Search results ',
  fileFomatInvalid: 'Please choose file PNG or JPG.',
  invalidFileName: 'Invalid file name.',
  invalidFolderName: 'Invalid folder name.',
  shareFile: 'Public file',
  upload: 'Upload file',
  none: 'Original folder',
  download: 'Download',
  searchingFor: 'Search by keyword ',
  showMore: 'Load more',
  addDocument: 'Add document',
  addQuestions: 'Add questions',
  deleteDocument: 'Delete document',
  deleteQuestions: 'Delete questions',
  cancel: 'Cancel'
};
