export default {
  question: 'Questions',
  createQuestion: 'Create Question',
  updateQuestion: 'Update Question',
  addQuestions: 'Add Questions',
  documents: 'Documents',
  addDocument: 'Add Document',
  name: 'Name',
  action: 'Actions',
  level: 'Level',
  skill: 'Skill',
  type: 'Type',
  delete: 'Delete',
  invalid: 'Invalid',
  deleteDocuments: 'Delete documents',
  confirmDelete: 'Confirm delete',
  saved: 'Saved',
  filter: 'Filter',
  pleaseCheckAgainDesc:
    'Please check again the description of Fill in Gaps block and click outside the description after interaction.',

  list: {
    listQuestion: 'List Question',
    displayAll: 'Display all question',
    createNew: 'Create new',
    name: 'Name',
    level: 'Level',
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    advanced: 'Advanced',
    skill: 'Skill',
    listening: 'Listening',
    writing: 'Writing',
    reading: 'Reading',
    speaking: 'Speaking',
    time: 'Time',
    action: 'Actions',
    deleteQuestion: 'Delete question',
    confirmDeleteAll: 'Do you want delete all questions',
    confirmDelete: 'You want delete this question',
    deleteAllQuestion: 'Delete all questions',
    pleaseEnterAQuestion: 'Enter Question Name',
    deleteAll: 'Delete',
    questionManageMent: 'Questions management',
    filter: 'Filter',
    reset: 'Reset'
  },

  create: {
    name: 'Name',
    enterDescription: 'Description',
    nameOfQuestion: 'Name',
    level: 'Level',
    skill: 'Skill',
    description: 'Description',
    uploadFile: 'Upload File',
    missingName: 'Please Enter Name',
    vocabularies: 'Vocabulary',
    levelOfQuestion: 'Level',
    enterVocabularies: 'Vocabulary',
    createQuestion: 'Create Question',
    timeLimit: 'Time Limit (minutes)',
    placeHolderTimeLimit: 'Time Limit',
    placeHolderAnswerTimeLimit: 'Time Limit',
    placeHolderNoteTimeLimit: 'Time Limit',
    noteTimeLimit: 'Note’s Time Limit (seconds)',
    answerTimeLimit: 'Answer’s Time Limit (minutes)',
    missingLevel: 'Please select level',
    missingTimeLimit: 'Please enter time limit',
    upload: 'Upload File',
    explanation: 'Explanation',
    enterExplanation: 'Explanation'
  },

  rearrange: {
    question: 'Question',
    describle: 'Describle your question',
    theOnlyThing:
      'The only thing you’ll ever have to worry about is how to explain it to the kids.',
    haveTo:
      'have /to/ thing/ how /to/ explain/ it/ to/you’ll /ever/ the /kids/ worry/ about /is/ The/ only/',
    pleaseEnterDescription: 'Please enter your question',
    pleaseEnterQuestion: 'Please enter your question',
    questionIsNot: 'Question is not exceeded 255 characters',
    save: 'Save'
  },

  writeAFullEssay: {
    save: 'Save',
    enterADescription: 'Description',
    pleaseEnterADescription: 'Please enter a description of the question set',
    question: 'Description'
  },

  fillInGaps: {
    description: 'Description',
    question: 'Question',
    answer: 'Answer',
    save: 'Save',
    pleaseYourDesc: 'Please enter your description',
    pleaseYourAnswer: 'Please enter your answer',
    placeHolderDesc: 'Enter description...[1].....[2]',
    placeHolderAnswer: 'Answer',
    mustQuestion: 'must have at least 1 question'
  },

  mapLabelling: {
    description: 'Description',
    question: 'Question',
    answer: 'Answer',
    save: 'Save',
    pleaseYourDesc: 'Please enter your description',
    positive: 'Enter a positive integer',
    pleaseYourQuestion: 'Please enter your question',
    placeHolderQuestion: 'NO.',
    pleaseYourAnswer: 'Please enter your answer',
    placeHolderDesc: 'Enter a description of the question set',
    placeHolderAnswer: 'Answer',
    add: 'Add Answer'
  },

  trueFalse: {
    description: 'Description',
    question: 'Question',
    answer: 'Answer',
    save: 'Save',
    pleaseYourDesc: 'Please enter your description',
    positive: 'Enter a positive integer',
    pleaseYourQuestion: 'Please enter your answer',
    placeHolderAnswer: 'Select',
    pleaseYourAnswer: 'Please choose your answer',
    placeHolderDesc: 'Enter a description of the question set',
    add: 'Add Answer',
    pleaseYourIndex: 'Please enter question',
    placeHolderQuestion: 'Text content',
    placeHolderIndex: 'NO.'
  },

  yesNo: {
    description: 'Description',
    question: 'Question',
    answer: 'Answer',
    save: 'Save',
    pleaseYourDesc: 'Please enter your description',
    positive: 'Enter a positive integer',
    pleaseYourQuestion: 'Please enter your answer',
    placeHolderAnswer: 'Select',
    pleaseYourAnswer: 'Please choose your answer',
    placeHolderDesc: 'Enter a description of the question set',
    add: 'Add Answer',
    pleaseYourIndex: 'Please enter question',
    placeHolderQuestion: 'Text content',
    placeHolderIndex: 'NO.'
  },

  options: {
    true: 'True',
    false: 'False',
    ng: 'NG',
    listening: 'Listening',
    reading: 'Reading',
    writing: 'Writing',
    speaking: 'Speaking',
    multipleChoice: 'Multiple Choice',
    singleChoice: 'Single Choice',
    beginner: 'Beginner',
    advanced: 'Advanced',
    intermediate: 'Intermediate',
    yes: 'Yes',
    no: 'No',
    task1: 'Task 1',
    task2: 'Task 2'
  },

  matching: {
    mustMoreAnswer: 'Must not be less than the number of answers',
    enterDescription: 'Enter a description of the question set',
    missingDescription: 'Missing description',
    missingTitleListHeading: 'Missing title list heading',
    titleListHeading: 'Title List Heading',
    listHeading: 'Answer',
    titleQA: 'Title  Q&A',
    atLeast1Heading: 'At least 1 heading',
    missingHeading: 'Missing heading',
    enterYourHeading: 'Answer',
    addHeading: 'Add heading',
    missingTitleListAnswer: 'Missing title list answer',
    titleListAnswer: 'Title list answer',
    listAnswer: 'Q&A',
    atLeast1Answer: 'At least 1 answer',
    missingIndex: 'Index',
    index: 'NO.',
    question: 'Question',
    label: 'Label',
    answer: 'Answer',
    missingAnswer: 'Missing answer',
    enterYourAnswer: 'Answer',
    addAnswer: 'Add answer',
    selectHeading: 'Select correct heading',
    placeHolderListHeading: 'Title list heading',
    placeHolderTitleQA: 'Title Q&A',
    placeHolderQuestion: 'NO.',
    description: 'Description',
    listParagraph: 'List Paragraph',
    max1: 'Max 1 character',
    invalidLabel: 'Invalid Label',
    missingLabel: 'Missing Label',
    answerListParagraph: 'Answer List Paragraph'
  },

  multipleChoice: {
    enterDescription: 'Enter a description of the question',
    missingDescription: 'Missing description',
    atLeast2Answer: 'At least 2 answers',
    missingIndex: 'Index',
    missingAnswer: 'Missing answer',
    enterYourAnswer: 'Answer',
    addAnswer: 'Add answer',
    description: 'Description',
    answer: 'Answers',
    placeHolderQuestion: 'NO.',
    question: 'Question'
  },

  singleChoice: {
    enterDescription: 'Enter a description of the question',
    missingDescription: 'Missing description',
    atLeast2Answer: 'At least 2 answers',
    missingIndex: 'Index',
    missingAnswer: 'Missing answer',
    enterYourAnswer: 'Answer',
    addAnswer: 'Add answer',
    description: 'Description',
    answer: 'Answer',
    addBlock: 'Add block',
    placeHolderQuestion: 'NO.'
  }
};
