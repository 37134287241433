export default {
  updateEmail: 'Update Email',
  newpassword: 'Password',
  inputnewpassword: 'Re-enter password',
  confirmPass: 'Confirm password',
  saveInfo: 'Save',
  desUpdateEmail:
    'Please update login information to use  LUYỆN THI TIẾNG ANH website.',
  email: 'Email',
  plsInputEmail: 'Please enter Email.'
};
