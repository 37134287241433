export const classStatus = {
  preparing: 'preparing',
  ended: 'ended',
  started: 'started'
};

export const lessonStatus = {
  draft: 'draft',
  scheduled: 'scheduled',
  cancelled: 'cancelled',
  finished: 'finished',
  publish: 'publish'
};

export const answerStatus = {
  approve: 'approve',
  reject: 'reject'
};

export const lessonContentType = {
  saveDraft: 'saveDraft',
  publish: 'publish'
};

export const attendanceStatus = {
  present: 'present',
  late: 'late',
  leave: 'leave'
};

export const roleTypes = {
  create: 'create',
  view: 'view',
  edit: 'edit',
  destroy: 'destroy'
};

export const utilizationContest = {
  practice: 'practice',
  test: 'contest'
};

export const utilizationContests = {
  practice: 'practice',
  contest: 'contest'
};

export const enumTagCommon = {
  advanced: 'advanced',
  intermediate: 'intermediate',
  beginner: 'beginner',
  ended: 'ended',
  upcoming: 'upcoming',
  ongoing: 'ongoing',
  draft: 'draft',
  private: 'private',
  public: 'public',
  undone: 'undone',
  done: 'done',
  writing: 'writing',
  speaking: 'speaking',
  listening: 'listening',
  reading: 'reading',
  marked: 'marked',
  unmarked: 'unmarked',
  completed: 'completed',
  published: 'published',
  unpublished: 'unpublished'
};

export const ROLE = {
  teacher: 'teacher',
  student: 'student',
  admin: 'admin'
};

export const JoinedStatus = {
  NotJoined: 'not_joined',
  Joined: 'joined'
};

export const actionContest = {
  create: 'create',
  update: 'update'
};

export const navigationSettingsManager = {
  permissions: 'permissions',
  notifications: 'notifications'
};

export const navigationAccountSettings = {
  profileSettings: 'profileSettings',
  historyContest: 'historyContest',
  changePassword: 'changePassword',
  notifications: 'notifications'
};

export const navigationStudentProfile = {
  studentProfile: 'studentProfile',
  historyContest: 'historyContest'
};
