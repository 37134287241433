import layout from 'components/Layout/locales/en';
import layoutUser from 'components/LayoutUser/locales/en';
import groupRadioButton from 'components/Form/GroupRadioButton/locales/en';
import registerPage from 'containers/OtherPage/RegisterPage/locales/en';
import modalConfirm from 'components/commons/ModalConfirm/locales/en';
import modal from 'components/commons/Modal/locales/en';
import confirmPhone from 'containers/OtherPage/ConfirmNumberPhone/locales/en';
import updateEmail from 'containers/OtherPage/UpdateEmail/locales/en';
import table from 'components/commons/Table/locales/en';
import modalUploadFile from 'components/commons/ModalUploadFile/locales/en';
import tagCommon from 'components/commons/TagCommon/locales/en';

// new page
import documents from 'containers/TesAdminPage/Documents/locales/en';
import questions from 'containers/TesAdminPage/Questions/locales/en';
import loginPage from 'containers/OtherPage/LoginPage/locales/en';
import firstLogin from 'containers/OtherPage/FirstLogin/locales/en';
import forgotPasswordPage from 'containers/OtherPage/ForgotPasswordPage/locales/en';
import changePasswordPage from 'containers/OtherPage/ChangePasswordPage/locales/en';
import forgotSuccessPasswordPage from 'containers/OtherPage/ForgotSuccessPasswordPage/locales/en';
import employeeManager from 'containers/TesAdminPage/EmployeeManager/locales/en';
import folders from 'containers/TesAdminPage/Folders/locales/en';
import contest from 'containers/TesAdminPage/ContestManager/locales/en';
import student from 'containers/TesAdminPage/StudentManager/locales/en';
import monitoringAndGrading from 'containers/TesAdminPage/MonitoringAndGrading/locales/en';
import settingsManagement from 'containers/TesAdminPage/Settings/locales/en';

// student module
import studentContest from 'containers/TesStudentPage/Contest/locales/en';
import accountSettings from 'containers/TesStudentPage/AccountSettings/locales/en';
import contestResult from 'containers/TesStudentPage/Result/locales/en';

//class module
import classRoom from 'containers/TesAdminPage/Class/locales/en';

import messageStatus from './message/en';
import validation from './validation/en';

export default {
  layout,
  table,
  layoutUser,
  registerPage,
  modalConfirm,
  validation,
  groupRadioButton,
  modal,
  modalUploadFile,
  tagCommon,
  //
  messageStatus,
  confirmPhone,
  updateEmail,
  changePasswordPage,

  // new page
  documents,
  questions,
  loginPage,
  firstLogin,
  forgotPasswordPage,
  forgotSuccessPasswordPage,
  employeeManager,
  folders,
  contest,
  student,
  monitoringAndGrading,
  settingsManagement,
  // student module
  studentContest,
  accountSettings,
  contestResult,
  classRoom
};
