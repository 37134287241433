import React, { useEffect } from 'react';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Dropdown, Space } from 'antd';

import useTranslation from 'hooks/useTranslation';
import { logOut } from 'store/other/authSlice';
import { getNotifications } from 'store/other/notificationSlice';

import { utilizationContest } from 'utils/constants/enum';
import { ROUTES_USER } from 'routes/constants';
import { updateFCMToken } from 'services/apis/admin/notification';
import NotificationsUsers from 'components/commons/NotificationsUsers';

import logoTes from 'assets/image/logo-sidebar-te.png';

import * as S from './styled';

export default function LayoutUser({ children }) {
  const { t } = useTranslation('layoutUser');

  const history = useHistory();
  const dispatch = useDispatch();

  const { utilization } = useParams();

  const { isAuth, userInfo } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (isAuth) {
      dispatch(getNotifications());
    }
  }, [isAuth, dispatch]);

  const items = [
    {
      key: '1',
      label: (
        <p
          onClick={() =>
            history.push(generatePath(ROUTES_USER?.STUDENT_PROFILE))
          }
        >
          {t('profile')}
        </p>
      )
    },
    {
      key: '2',
      label: (
        <p
          onClick={() => {
            updateFCMToken(userInfo?.id, {
              fcm_token: ''
            }).catch(() => {
              return;
            });
            dispatch(logOut());
          }}
        >
          {t('logOut')}
        </p>
      )
    }
  ];

  return (
    <>
      <S.Hd>
        <S.Logo
          onClick={() =>
            history.push(
              generatePath(ROUTES_USER.CONTEST, {
                utilization: utilizationContest.test
              })
            )
          }
        >
          <img src={logoTes} alt="logo" />
        </S.Logo>

        <S.MenuHeadWrap>
          <S.MenuHeadItem
            $isActive={utilization === utilizationContest.test}
            onClick={() =>
              history.push(
                generatePath(ROUTES_USER.CONTEST, {
                  utilization: utilizationContest.test
                })
              )
            }
          >
            {t(utilizationContest.test).toUpperCase()}
          </S.MenuHeadItem>
          <S.MenuHeadItem
            $isActive={utilization === utilizationContest.practice}
            onClick={() =>
              history.push(
                generatePath(ROUTES_USER.CONTEST, {
                  utilization: utilizationContest.practice
                })
              )
            }
          >
            {t(utilizationContest.practice).toUpperCase()}
          </S.MenuHeadItem>
        </S.MenuHeadWrap>

        <S.NotificationWrap>
          <NotificationsUsers />
          <S.UserWrap>
            <S.UserName>{userInfo?.first_name}</S.UserName>
            <Dropdown
              menu={{ items }}
              placement="bottom"
              autoAdjustOverflow
              overlayClassName="dropdown-header"
            >
              <Space>
                <Avatar
                  shape="circle"
                  size={48}
                  src={
                    userInfo?.avatar
                      ? `${userInfo?.avatar}?v=${new Date().getTime()}`
                      : 'https://app.questn.com/static/users/avatar8.png'
                  }
                ></Avatar>
              </Space>
            </Dropdown>
          </S.UserWrap>
        </S.NotificationWrap>
      </S.Hd>
      <S.Layout>{children}</S.Layout>
    </>
  );
}
